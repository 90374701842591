"use strict";
import { ScrollLock } from "./_scrollLock";
// import { SmoothScroll } from "./_smoothScroll";

export class Drawer {
  #header;
  #drawer;
  #button;
  #bottomButton;
  // #closeButton;
  #backdrop;
  #anchorLinks;
  #drawerOpen = false;
  #scrollLock;
  // #smoothScroll;
  // キーボード操作
  #firstTabbable;
  #lastTabbable;

  constructor(elements) {
    const { header, drawer, button, anchorLinks, backdrop, bottomButton } =
      elements;
    this.#header = header;
    this.#drawer = drawer;
    this.#button = button;
    this.#bottomButton = bottomButton;
    // this.#closeButton = closeButton;
    this.#backdrop = backdrop;
    this.#anchorLinks = anchorLinks;

    this.#scrollLock = new ScrollLock();
    // this.#smoothScroll = new SmoothScroll();

    // キーボード操作
    const tabbableElements = this.#drawer.querySelectorAll(
      "a[href], button:not(:disabled)"
    );
    this.#firstTabbable = tabbableElements[0];
    this.#lastTabbable = tabbableElements[tabbableElements.length - 1];

    this.#button.addEventListener("click", this.#onClickButton);
    // this.#closeButton.addEventListener("click", this.#onClickButton);
    window.addEventListener("keydown", this.#onKeydownEsc);
    window.addEventListener("keydown", this.#onKeydownTabKeyFirstTabbable);
    window.addEventListener("keydown", this.#onKeydownTabKeyLastTabbable);
    this.#backdrop.addEventListener("click", this.#onClickBackdrop);

    if (this.#anchorLinks) {
      this.#anchorLinks.forEach((link) => {
        link.addEventListener("click", this.#onClickAnchorLink);
      });
    }
    if (this.#bottomButton) {
      this.#bottomButton.addEventListener("click", this.#onClickButton);

      // this.#bottomButton.forEach((bottomButton) => {
      // });
    }
  }

  #changeAriaExpanded = (state) => {
    const value = state ? "true" : "false";
    this.#drawer.setAttribute("aria-expanded", value);
    this.#button.setAttribute("aria-expanded", value);
    // this.#closeButton.setAttribute("aria-expanded", value);
    // this.#header.classList.toggle(IS_ACTIVE_CLASS, state);
    this.#header.classList.toggle("is-open", state);
  };

  #changeState = (state) => {
    if (state === this.#drawerOpen) return;
    this.#changeAriaExpanded(state);
    this.#drawerOpen = state;
  };

  #openDrawer = () => {
    this.#changeState(true);
  };

  #closeDrawer = () => {
    this.#changeState(false);
  };

  #onKeydownTabKeyFirstTabbable = (event) => {
    if (event.key !== "Tab" || !event.shiftKey) return;
    event.preventDefault();
    this.#lastTabbable.focus();
  };

  #onKeydownTabKeyLastTabbable = (event) => {
    if (event.key !== "Tab" || event.shiftKey) return;
    event.preventDefault();
    this.#firstTabbable.focus();
  };

  #onKeydownEsc = (event) => {
    if (!this.#drawerOpen || event.key !== "Escape") return;
    event.preventDefault();
    this.#closeDrawer();
    this.#scrollLock.deactivate();
  };

  #onClickButton = () => {
    if (this.#drawerOpen === false) {
      this.#openDrawer();
      this.#scrollLock.activate();
      // this.#firstTabbable.focus();
      return;
    }
    this.#closeDrawer();
    this.#scrollLock.deactivate();
  };

  #onClickBackdrop = () => {
    this.#onClickButton();
  };

  #onClickAnchorLink = (e) => {
    // e.preventDefault(); // preventDefaultを削除
    const href = e.target.getAttribute("href");
    this.#closeDrawer();
    this.#scrollLock.deactivate();
    if (href && href.startsWith("#")) {
      const targetElement = document.querySelector(href);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
}
