"use strict";

// ===========================================
//  variable / 変数宣言
// ===========================================
// アクティブなクラス
const IS_ACTIVE = "is-active";
const drawerNavItems = document.querySelectorAll(".header-drawer-nav-item");
const drawerFigureItems = document.querySelectorAll(".header-content-figure");

// ===========================================
//  library / ライブラリ
// ===========================================
import { mediaQuery } from "./utility/_variable";
import { initSpanWrap } from "./utility/_spanWrap";
import { SmoothScroll } from "./utility/_smoothScroll";
import { Drawer } from "./utility/_drawer";
import * as modGsap from "./utility/_gsap";

// ===========================================
//  setup /
// ===========================================
modGsap.init({ scrollTrigger: true });
modGsap.init();

const setupDrawer = () => {
  const header = document.querySelector(".js-header");
  const bottomButton = document.querySelector(".other-content-button");
  const drawer = document.querySelector(".js-drawer");
  const button = document.querySelector(".header-btn");
  const backdrop = document.querySelector(".js-backdrop");
  const elements = {
    bottomButton,
    header,
    drawer,
    button,
    backdrop,
  };
  new Drawer(elements);
};
// ===========================================
//  function / 処理
// ===========================================
const headerHoverAction = () => {
  drawerNavItems.forEach((item, index) => {
    item.addEventListener("mouseover", () => {
      drawerFigureItems[index].classList.add("is-hover");
    });
    item.addEventListener("mouseleave", () => {
      drawerFigureItems[index].classList.remove("is-hover");
    });
  });
};
// ===========================================
//  init / 初期化
// ===========================================
const init = () => {
  const floatingEl = document.querySelectorAll(".floating-obj");

  floatingEl.forEach((element) => {
    const randomDuration = Math.random() * 8 + 4; // ランダムな時間（1秒から4秒の間）
    element.style.animationDuration = `${randomDuration}s`;
  });
  initSpanWrap();
  setupDrawer();
  const links = document.querySelectorAll(`a[href*="#"]`);
  new SmoothScroll(links);
  headerHoverAction();
  // stickeysalesButton();
  // indexVerticalScroll();
};
// ===========================================
//  load / 読み込み
// ===========================================

window.addEventListener("DOMContentLoaded", () => {
  init();
});
