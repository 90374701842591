"use strict";

export class ScrollLock {
  #body = document.body;
  #scrollY = 0;
  activate = () => {
    // this.#scrollY = window.scrollY;
    // this.#body.style.top = `-${this.#scrollY}px`;
    this.#body.classList.add("scroll-locked");
  };
  deactivate = () => {
    // this.#body.style.top = "auto";
    this.#body.classList.remove("scroll-locked");
    // window.scrollTo(0, this.#scrollY);
  };
}
