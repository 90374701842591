"use strict";
import { stripUnit } from "./_stripUnit";

export class SmoothScroll {
  #links;

  constructor(links) {
    this.#links = links;
    this.#links.forEach((link) => {
      link.addEventListener("click", (e) => {
        this.#moveAnchorLink(e);
      });
    });
  }

  #moveAnchorLink = (e) => {
    const target = e.currentTarget;
    e.preventDefault();
    const targetId = target.hash;
    const href = target.href;
    const currentURL = location.href;
    if (currentURL.indexOf("#") > -1) currentURL = currentURL.split("#")[0];
    if (href.split("#")[0] != currentURL) {
      window.location.href = href;
    } else {
      const param = href.substring(href.indexOf("?"));

      if (
        target.hasAttribute("target") &&
        target.getAttribute("target") == "_blank"
      ) {
        open(target.href, "_blank");
      } else {
        if (href.indexOf("?") > -1) {
          location.href = href.replace(param, "");
        } else {
          if (
            href.indexOf(currentURL) == -1 ||
            location.pathname == "/" ||
            href.split("#")[0] != currentURL
          ) {
            location.href = href;
          } else if (href.indexOf(currentURL) > -1) {
            const targetElement = document.querySelector(targetId);
            this.#scrollToElement(targetElement);
          }
        }
      }
    }
  };

  #scrollToElement = (targetElement, duration = 1000) => {
    const targetPosition =
      targetElement.getBoundingClientRect().top + window.pageYOffset;
    const startPosition = window.pageYOffset;

    // scroll-margin-topを考慮する
    const scrollMarginTop =
      stripUnit(
        window
          .getComputedStyle(targetElement)
          .getPropertyValue("scroll-margin-top")
      ) || 0;
    const adjustedTargetPosition = targetPosition - scrollMarginTop;

    const distance = adjustedTargetPosition - startPosition;
    let startTime = null;

    const animation = (currentTime) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = this.#ease(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    requestAnimationFrame(animation);
  };

  #ease = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };
}
